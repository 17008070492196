export type Article = {
  id: string;
  title: string;
  summary: string;
  body: string;
  categories: string[];
  bookAuthor: string;
  date: string;
  readCount: number;
}

export interface ArticlesProps {
  articles: Article[];
  loading: boolean;
}

export enum Category {
  PHYSICS = 'Physics',
  PHILOSOPHY = 'Philosophy',
  MATHEMATICS = 'Mathematics',
  LIFE = 'Life',
  BOOKREVIEW = 'Book review',
  PROGRAMMING = 'Programming',
  TECHNOLOGY = 'Technology',
}

export function getCategoryLabel(cat: string) {
  switch (cat) {
    case 'physics':
      return Category.PHYSICS;
    case 'philosophy':
      return Category.PHILOSOPHY;
    case 'mathematics':
      return Category.MATHEMATICS;
    case 'life':
      return Category.LIFE;
    case 'book_review':
      return Category.BOOKREVIEW;
    case 'programming':
      return Category.PROGRAMMING;
    case 'technology':
      return Category.TECHNOLOGY;
    default:
      return Category.LIFE;
  }
}

export interface IQuery {
  sortBy: SortCategory;
  categories: string[];
  sortOrder: SortOrder;
}

export enum SortOrder {
  ASC = 1,
  DEC = 0,
}

export enum SortCategory {
  Date = "Date",
  Popularity = "Popularity"
}

export const initialQuery: IQuery = {
  sortBy: SortCategory.Date,
  categories: ['all'],
  sortOrder: SortOrder.DEC
}

export const dummyArticle: Article = {
  id: '',
  title: 'Lorem ipsum',
  summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at tortor venenatis, iaculis velit quis, sagittis ante. Proin volutpat ipsum ut ipsum ultrices blandit. Fusce tempus odio sapien, eget semper ipsum posuere non.',
  body: '',
  categories: ['philosophy'],
  bookAuthor: '',
  date: '11 Feb, 2022',
  readCount: 1
};

export interface ISectionProps {
  loading: boolean;
}