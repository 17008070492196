import githubIcon from '../../media/icons/github_icon.svg';
import './Footer.scss';

function Footer() {
  return (
    <div className='footer-container'>
      <span>Made in 2022 by</span>
      <a
        href="https://github.com/kasparas-ban/Personal-Blog"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={githubIcon} alt="search icon" />
      </a>
    </div>
  );
}

export default Footer;