import { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SearchField from '../SearchField/SearchField';
import barsIcon from '../../media/icons/bars_icon.svg';
import crossIcon from '../../media/icons/cross_icon.svg';
import './Navbar.scss';
import './SearchStyle.scss';
import useFetch from '../../Shared/useFetch';
import { API } from '../../Endpoints/api-enpoints';
import { getSearchResults, IResults, ISearchResultsProps } from '../SearchField/SearchUtil';

interface INavbarDrawerProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchOpen: boolean;
  setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Navbar() {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <>
      <div className="navbar-container">
        <img src={barsIcon} alt="bars icon" className="bars-icon" onClick={() => setIsDrawerOpen(true)} />
        <div className="logo" onClick={() => navigate('/')}>Placid Talk</div>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? 'navbar-link active' : 'navbar-link')}
        >
          Home
        </NavLink>
        <NavLink
          to="/archive"
          className={({ isActive }) => (isActive ? 'navbar-link active' : 'navbar-link')}
        >
          Archive
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? 'navbar-link active' : 'navbar-link')}
        >
          About
        </NavLink>

        <div
          className='searchIconDrawer'
          onClick={() => {
            setIsDrawerOpen(true);
            setIsSearchOpen(true);
          }}>
        </div>
        <SearchField />
      </div>
      <NavbarDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        isSearchOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
      />
    </>
  );
}

function NavbarDrawer({ isOpen, setIsOpen, isSearchOpen, setIsSearchOpen }: INavbarDrawerProps) {
  return (
    <div className={isOpen ? 'navbar-drawer' : 'navbar-drawer-hidden'}>
      <img
        src={crossIcon}
        alt="cross icon"
        className="cross-icon"
        onClick={() => {
          setIsOpen(false);
          setIsSearchOpen(false);
        }}
      />
      {isSearchOpen
        ? <SearchFieldDrawer setIsOpen={setIsOpen} />
        : <>
          <Link to="/" className="navbar-link" onClick={() => setIsOpen(false)}>Home</Link>
          <Link to="/archive" className="navbar-link" onClick={() => setIsOpen(false)}>Archive</Link>
          <Link to="/about" className="navbar-link" onClick={() => setIsOpen(false)}>About</Link>
        </>
      }
    </div>
  );
}

interface ISearchFieldDrawerProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function SearchFieldDrawer({ setIsOpen }: ISearchFieldDrawerProps) {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<IResults[]>([]);

  const { data, loading, execute } = useFetch(API.getAllArticlesData, true);

  useEffect(() => {
    if (execute) execute(API.getAllArticlesData);
  }, [searchText]);

  useEffect(() => {
    setSearchResults(getSearchResults(data || [], searchText));
  }, [data]);

  return (
    <>
      <input id="input_search_drawer" type="text" placeholder='Type to search'
        value={searchText}
        onChange={
          (val) => setSearchText(val.target.value)
        }
      />
      {loading
        ? <div>Loading...</div>
        : <SearchResultsDrawer showResults={true} results={searchResults} setSearchText={setSearchText} setIsOpen={setIsOpen} />
      }
      {/* <SearchResultsDrawer showResults={true} results={searchResults} setSearchText={setSearchText} setIsOpen={setIsOpen} /> */}
    </>
  );
}

function SearchResultsDrawer({ showResults, results, setSearchText, setIsOpen }: ISearchResultsProps) {
  const navigate = useNavigate();
  return (
    <div className={`search-results-drawer ${!showResults && 'hidden'}`}>
      {results.map((article, indx) => (
        <div
          className={`search-entry ${results.length - 1 === indx ? 'last-entry' : ''}`}
          key={indx}
          onClick={() => {
            setSearchText('');
            if (setIsOpen) setIsOpen(false);
            navigate(`/article/${article.articleId}`);
          }}
        >
          <div className="search-title">{article.title}</div>
          <div className="search-body">
            {article.usage.slice(0, article.startPosition)}
            <span className="found-text">{article.usage.slice(article.startPosition, article.endPosition)}</span>
            {article.usage.slice(article.endPosition, article.usage.length)}
          </div>
        </div>
      ))}
    </div>)
}


export default Navbar;