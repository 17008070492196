import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../Endpoints/api-enpoints";
import { getSearchResults, IResults, ISearchResultsProps } from "./SearchUtil";
import { dummyArticle } from "../../Shared/ArticlesInterfaces";
import useFetch from "../../Shared/useFetch";
import './SearchField.scss';

function SearchField() {
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<IResults[]>([]);
  const wrapperRef = useRef(null);

  const { data, loading, execute } = useFetch(API.getAllArticlesData, true);

  useEffect(() => {
    if (execute && !!searchText) execute(API.getAllArticlesData);
    setSearchResults(getSearchResults(data || [], searchText));
  }, [searchText]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !(wrapperRef.current as any).contains(event.target)) {
        setSearchText("");
        setIsSearchFocused(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <label
      className={`search${isSearchFocused ? " search-active" : ""}`}
      ref={wrapperRef}
      htmlFor="input_search"
      onClick={() => setIsSearchFocused(true)}
    >
      <input id="input_search" type="text" placeholder='Type to search'
        value={searchText}
        onChange={
          (val) => setSearchText(val.target.value)
        }
      />
      {loading && isSearchFocused
        ? <SearchResultsLoading />
        : searchResults.length !== 0
          ? <SearchResults
            showResults={isSearchFocused && !!searchText}
            results={searchResults}
            setSearchText={setSearchText}
            setIsSearchFocused={setIsSearchFocused} />
          : <SearchResultsEmpty showResults={isSearchFocused && !!searchText} />
      }
    </label>
  );
}

function SearchResults({ showResults, results, setSearchText, setIsSearchFocused }: ISearchResultsProps) {
  const navigate = useNavigate();
  return (
    <div className={`search-results ${!showResults && 'hidden'}`}>
      {results.map((article, indx) => (
        <div
          className={`search-entry ${results.length - 1 === indx ? 'last-entry' : ''}`}
          key={indx}
          onClick={() => {
            setSearchText('');
            setTimeout(() => { setIsSearchFocused && setIsSearchFocused(false) }, 50);
            navigate(`/article/${article.articleId}`);
          }}
        >
          <div className="search-title">{article.title}</div>
          <div className="search-body">
            {article.usage.slice(0, article.startPosition)}
            <span className="found-text">{article.usage.slice(article.startPosition, article.endPosition)}</span>
            {article.usage.slice(article.endPosition, article.usage.length)}
          </div>
        </div>
      ))}
    </div>)
}

function SearchResultsLoading() {
  const loadingScreen = Array(3).fill(dummyArticle).map((article, index) => (
    <div className="search-entry" key={index}>
      <div className="search-title">{article.title}</div>
      <div className="search-body">
        {`...volutpat ipsum ut ipsum ultrices... `}
      </div>
    </div>
  ));

  return (
    <div className="search-results loading">
      {loadingScreen}
    </div>
  );
}

interface ISearchResultsEmptyProps {
  showResults: boolean;
}

function SearchResultsEmpty({ showResults }: ISearchResultsEmptyProps) {
  return (
    <div className={`search-results empty ${!showResults && 'hidden'}`}>
      No results found.
    </div>
  );
}

export default SearchField;
