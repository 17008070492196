import { useNavigate } from "react-router-dom";
import { Article, getCategoryLabel } from "../../Shared/ArticlesInterfaces";
import './ArticleSummary.scss';

interface IArticleSummaryProps {
  index: number;
  article: Article;
}

function ArticleSummary({ article }: IArticleSummaryProps) {
  const navigate = useNavigate();
  const handleClick = () => navigate(`/article/${article.id}`);

  return (
    <div className='article' onClick={handleClick}>
      <div className='article-category'>{article.categories.map((cat, indx) => (
        <span className="category-label" key={indx}>{getCategoryLabel(cat)}</span>
      ))}</div>
      <div>
        <span className='article-name'>{article.title}</span>
        {article.bookAuthor && <span className='book-author'>by {article.bookAuthor}</span>}
      </div>
      <div className='article-intro'>{article.summary}</div>
    </div>
  );
}

export default ArticleSummary;