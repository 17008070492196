import { IQuery, SortCategory } from "../Shared/ArticlesInterfaces";

export const API = {
  getAllArticlesData: "https://placid-talk-server.herokuapp.com/api/articlesData/",
  getArticleData: "https://placid-talk-server.herokuapp.com/api/articleData/:id",
  getRecentlyAdded: "https://placid-talk-server.herokuapp.com/api/articlesData/?recentlyAdded=true",
  // getAllArticlesData: "/api/articlesData/",
  // getArticleData: "/api/articleData/:id",
  // getRecentlyAdded: "/api/articlesData/?recentlyAdded=true",
}

export const formQuery = (query: IQuery) => {
  let queryString = '?';

  if (query.sortBy === SortCategory.Date) {
    queryString += 'sortByDate=true';
  } else {
    queryString += 'sortByPopularity=true';
  }
  
  if (!query.categories.includes('all')) {
    query.categories.map((cat) => queryString += `&categories[]=${cat}`)
  }

  queryString += `&sortOrder=${query.sortOrder}`;

  return queryString;
}