import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import Home from './Pages/Home/Home';
import Footer from './Components/Footer/Footer';
import Article from "./Pages/ArticlePage/ArticlePage";
import Archive from "./Pages/Archive/Archive";
import About from "./Pages/About/About";
import './App.scss';

function App() {
  return (
    <>
      <div className="App">
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/archive" element={<Archive />}></Route>
            <Route path="/article/:articleId" element={<Article />}></Route>
            <Route path="/about" element={<About />}></Route>
          </Routes>
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
