import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCategoryLabel } from '../../Shared/ArticlesInterfaces';
import { API } from '../../Endpoints/api-enpoints';
import useFetch from '../../Shared/useFetch';
import './ArticlePage.scss';

function ArticlePage() {
  let { articleId } = useParams();

  const { data, loading, error, execute } = useFetch(API.getArticleData.replace(':id', articleId || ''));
  const articleData = data && data[0];

  useEffect(() => {
    if (execute) execute(API.getArticleData.replace(':id', articleId || ''));
  }, [articleId]);

  const articleText =
    (loading ?
      <LoadingArticleScreen />
      : (<div className='article-container'>
        {articleData && (
          <>
            <div className='article-info'>
              <span className='info-label date'>{articleData.date}</span>
              {articleData.categories.map((cat, index) => (
                <span className='info-label' key={index}>{getCategoryLabel(cat)}</span>
              ))}
            </div>
            <div className='article-name'>
              <div className='article-title'>{articleData.title}</div>
              <div className='article-author'>{'by ' + articleData.bookAuthor}</div>
            </div>
            <div className='article-body'>
              {articleData.body}
            </div>
          </>
        )}
      </div>));

  return (
    error ? FailedFetchSection() : articleText
  );
}

function LoadingArticleScreen() {
  return (
    <div className="article-container error-message">Loading data...</div>
  );
}

function FailedFetchSection() {
  return (
    <div className='articles-section error-message'>
      <div>Failed to receive the article. Is the server down?</div>
      <div>Maybe try to reload the page...</div>
    </div>
  );
}

export default ArticlePage;