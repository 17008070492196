import { useNavigate } from 'react-router-dom';
import useFetch from '../../Shared/useFetch';
import { API } from '../../Endpoints/api-enpoints';
import { ArticlesProps, dummyArticle, ISectionProps } from '../../Shared/ArticlesInterfaces';
import ArticleSummary from '../../Components/ArticleSummary/ArticleSummary';
import './Home.scss';

function Home() {
  const { data, loading, error } = useFetch(API.getAllArticlesData);
  return (
    <>
      <QuoteSection />
      <div className='home-container'>
        {error
          ? (<FailedFetchSection />)
          : (
            <>
              <ArticlesSection articles={data || []} loading={loading} />
              <SidePanel />
            </>
          )
        }
      </div>
    </>
  );
}

function FailedFetchSection() {
  return (
    <div className='articles-section error-message'>
      <div>Failed to receive any articles. Is the server down?</div>
      <div>Maybe try to reload the page...</div>
    </div>
  );
}

function QuoteSection() {
  return (
    <div className='quote-section'>
      “Life is far too important a thing ever to talk seriously about.”  ― Oscar Wilde
    </div>
  );
}

function ArticlesSection({ articles, loading }: ArticlesProps) {
  return (
    <div className='articles-section'>
      <SectionTitle loading={loading} />
      {loading ?
        <div className='articles-loading'>
          <ArticleSummary index={1} article={dummyArticle} />
          <ArticleSummary index={2} article={dummyArticle} />
          <ArticleSummary index={3} article={dummyArticle} />
        </div>
        : (<>
          {articles.map((article, index) => (
            <div key={index}>
              <ArticleSummary index={index} article={article} />
            </div>
          ))}
        </>)
      }
    </div>
  );
}

function SectionTitle({ loading }: ISectionProps) {
  const mostReadText = <div className="separator-name">Most read</div>;
  const loadingText = <div className="separator-name">Loading articles...</div>;
  return (loading ? loadingText : mostReadText);
}

function SidePanel() {
  const { data, loading, error } = useFetch(API.getRecentlyAdded);
  const navigate = useNavigate();
  const handleClick = (id: string) => navigate(`/article/${id}`);

  const loadingScreen = Array(3).fill(dummyArticle).map((article, index) => (
    <div className='article-link' key={index} onClick={() => handleClick(article.id)}>
      <div>{article.title}</div>
      <div className='article-date'>{article.date}</div>
    </div>
  ));

  const recentlyAddedArticles = data && data.map((article, index) => (
    <div className='article-link' key={index} onClick={() => handleClick(article.id)}>
      <div>{article.title}</div>
      <div className='article-date'>{article.date}</div>
    </div>
  ));

  const recentlyAddedText = 'Recently Added Articles';
  const loadingText = 'Loading...';

  return (
    <div className={loading ? 'side-panel side-panel-loading' : 'side-panel'}>
      <div className='title'>{loading ? loadingText : recentlyAddedText}</div>
      {loading ? loadingScreen : recentlyAddedArticles}
    </div>
  );
}

export default Home;