import { useEffect, useState } from 'react';
import { ArticlesProps, dummyArticle, initialQuery, IQuery, ISectionProps, SortCategory } from '../../Shared/ArticlesInterfaces';
import ArticleSummary from '../../Components/ArticleSummary/ArticleSummary';
import { API, formQuery } from '../../Endpoints/api-enpoints';
import arrowIcon from '../../media/icons/arrow_icon.svg';
import useFetch from '../../Shared/useFetch';
import './Archive.scss';

function Archive() {
  const [query, setQuery] = useState<IQuery>(initialQuery);
  const { data, loading, error, execute } = useFetch(API.getAllArticlesData + formQuery(query), true);

  useEffect(() => {
    console.log('useEffect', query)
    if (execute) execute(API.getAllArticlesData + formQuery(query));
  }, [query]);

  return (
    <div className='archive-section'>
      <SortSection query={query} setQuery={setQuery} />
      <SortedSeparator loading={loading} />
      {error
        ? (<FailedFetchSection />)
        : (<SortedArticlesSection articles={data || []} loading={loading} />)
      }
    </div>
  );
}

function FailedFetchSection() {
  return (
    <div className='articles-section error-message'>
      <div>Failed to receive any articles. Is the server down?</div>
      <div>Maybe try to reload the page...</div>
    </div>
  );
}

function SortedArticlesSection({ articles, loading }: ArticlesProps) {

  return (loading
    ? (<div className='sort-articles articles-loading'>
      {Array(3).fill(dummyArticle).map((article, index) => (
        <div className='sort-row' key={index}>
          <div className='sort-category'>{article.date}</div>
          <ArticleSummary index={index} article={article} />
        </div>
      ))}
    </div>)
    : (<div className='sort-articles'>
      {articles.map((article, index) => (
        <div className='sort-row' key={index}>
          <div className='sort-category'>{article.date}</div>
          <ArticleSummary index={index} article={article} />
        </div>
      ))}
    </div>)
  );
}

function SortedSeparator({ loading }: ISectionProps) {
  return (
    loading
      ? <div className="separator-sort">Loading...</div>
      : <div className="separator-sort">Sorted by date</div>
  );
}

interface IQueryProps {
  query: IQuery;
  setQuery: React.Dispatch<React.SetStateAction<IQuery>>;
}

function SortSection({ query, setQuery }: IQueryProps) {
  const typeClass = 'selection';
  const [selected, setSelected] = useState<SortCategory>(SortCategory.Date);
  const getClass = (type: string) => type === selected ? typeClass + ' selected' : typeClass;

  const handleClick = (sel: SortCategory) => {
    setQuery((prev) => (sel === SortCategory.Date
      ? { ...prev, sortBy: SortCategory.Date }
      : { ...prev, sortBy: SortCategory.Popularity })
    );
    setSelected(() => sel);
  };

  return (
    <div className='sort-section'>
      <div className='sort-first-line'>
        <div className='sort-label'>Sort by:</div>
        <div className={getClass('Date')} onClick={() => handleClick(SortCategory.Date)}>Date</div>
        <div className={getClass('Popularity')} onClick={() => handleClick(SortCategory.Popularity)}>Popularity</div>
      </div>
      <CategoryType query={query} setQuery={setQuery} />
      <ArrowType query={query} setQuery={setQuery} />
    </div>
  );
}

function ArrowType({ query, setQuery }: IQueryProps) {
  const [selectedType, setSelectedType] = useState(true);

  useEffect(() => {
    if ((selectedType ? 0 : 1) !== query.sortOrder)
      setQuery((prev) => { return { ...prev, sortOrder: selectedType ? 0 : 1 } })
  }, [selectedType])

  return (
    <div className='sort-third-line'>
      <div
        className="sort-label">
        Sort order:
      </div>
      <img
        src={arrowIcon}
        alt="down arrow"
        className={`down-arrow-icon${selectedType ? ' selected-icon' : ''}`}
        onClick={() => setSelectedType(true)}
      />
      <img
        src={arrowIcon}
        alt="up arrow"
        className={`up-arrow-icon${!selectedType ? ' selected-icon' : ''}`}
        onClick={() => setSelectedType(false)}
      />
    </div>
  );
}

function CategoryType({ query, setQuery }: IQueryProps) {
  const [selectedCat, setSelectedCat] = useState<string[]>(['all']);
  const getClass = (cat: string) => selectedCat.includes(cat) ? 'selection selected' : 'selection';
  const handleClick = (cat: string) => {
    if (cat === 'all') {
      setSelectedCat([cat]);
    } else {
      selectedCat.includes(cat)
        ? (selectedCat.length === 1
          ? setSelectedCat(['all'])
          : setSelectedCat(() => selectedCat.filter(el => el !== cat)))
        : setSelectedCat([...selectedCat.filter(el => el !== 'all'), cat]);
    }
  }

  useEffect(() => {
    const isEqual = query.categories.length === selectedCat.length &&
      query.categories.every((elem) => selectedCat.includes(elem));
    if (!isEqual) setQuery((prev) => { return { ...prev, categories: selectedCat } });
  }, [selectedCat]);

  return (
    <div className='sort-second-line'>
      <div
        className="sort-label">
        Category:
      </div>
      <div
        className={getClass('all')}
        onClick={() => setSelectedCat(() => ['all'])}>
        All
      </div>
      <div
        className={getClass('philosophy')}
        onClick={() => handleClick('philosophy')}>
        Philosophy
      </div>
      <div
        className={getClass('technology')}
        onClick={() => handleClick('technology')}>
        Technology
      </div>
      <div
        className={getClass('book_review')}
        onClick={() => handleClick('book_review')}>
        Book review
      </div>
      <div
        className={getClass('life')}
        onClick={() => handleClick('life')}>
        Life
      </div>
      <div
        className={getClass('programming')}
        onClick={() => handleClick('programming')}>
        Programming
      </div>
      <div
        className={getClass('mathematics')}
        onClick={() => handleClick('mathematics')}>
        Mathematics
      </div>
      <div
        className={getClass('physics')}
        onClick={() => handleClick('physics')}>
        Physics
      </div>
    </div>
  );
}

export default Archive;