import aboutText from './aboutText';
import './About.scss';

function Article() {

  return (
    <div className='about-container'>
      <div className='about-title'>About</div>
      <div className='about-body'>{aboutText}</div>
    </div>
  );
}

export default Article;