import { Article } from "../../Shared/ArticlesInterfaces";

export interface ISearchResultsProps {
  showResults: boolean;
  results: IResults[];
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setIsSearchFocused?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IResults {
  title: string;
  usage: string;
  startPosition: number;
  endPosition: number;
  articleId: string;
}

export function getSearchResults(data: Article[], searchText: string) {
  let results: IResults[] = [];
  const text = searchText.toLowerCase();

  if (searchText)
    data.map((article) => {
      if (article.title.toLowerCase().includes(text)) {
        results.push({
          title: article.title,
          usage: article.title,
          startPosition: article.title.toLowerCase().indexOf(text),
          endPosition: article.title.toLowerCase().indexOf(text) + text.length,
          articleId: article.id,
        });
      } else if (article.bookAuthor.toLowerCase().includes(text)) {
        results.push({
          title: article.title,
          usage: article.bookAuthor,
          startPosition: article.bookAuthor.toLowerCase().indexOf(text),
          endPosition: article.bookAuthor.toLowerCase().indexOf(text) + text.length,
          articleId: article.id,
        });
      } else if (article.summary.toLowerCase().includes(text)) {
        results.push({
          title: article.title,
          usage: article.summary,
          startPosition: article.summary.toLowerCase().indexOf(text),
          endPosition: article.summary.toLowerCase().indexOf(text) + text.length,
          articleId: article.id,
        });
      } else if (article.date.toLowerCase().includes(text)) {
        results.push({
          title: article.title,
          usage: article.date,
          startPosition: article.date.toLowerCase().indexOf(text),
          endPosition: article.date.toLowerCase().indexOf(text) + text.length,
          articleId: article.id,
        });
      }
    });

  return results;
}