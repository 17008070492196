import { useState, useEffect } from 'react';
import { Article } from './ArticlesInterfaces';

type ApiResponse = {
  data?: Article[];
  error: any;
  loading: boolean;
  execute?: (url: string) => Promise<void>;
};

const useFetch = (url: string, delay?: boolean): ApiResponse => {
  const [data, setData] = useState<Article[]>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getAPIData = async (url: string) => {
    setLoading(true);
    try {
      const apiResponse = await fetch(url);
      const json = await apiResponse.json();
      setData(json as Article[]);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const execute = (url: string) => getAPIData(url);

  useEffect(() => {
    if (!delay) getAPIData(url);
  }, []);

  return { data, error, loading, execute };
};

export default useFetch;